.title-container {
  width: min(80%, 80rem);
  margin-inline: auto;
}

.title {
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-family: var(--font-accent);
}

.title-container hr {
  width: 20%;
  margin: 20px auto 40px;
}

@media (min-width: 800px) {
  .title {
    font-size: 4rem;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 1.5rem;
  }
}
