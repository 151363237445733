.footer-container {
  width: 100%;
  background-color: var(--clr-primary);
  color: var(--clr-gray);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 500px) {
  .footer-container {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  footer {
    max-width: 300px;
  }
}
