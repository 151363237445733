.about-page {
  margin: 120px 0 80px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 2;
  width: min(90%, 60rem);
  margin-inline: auto;
}

.about-br {
  font-family: var(--font-title);
}

.about hr {
  width: 30%;
}

.about h1 {
  font-size: 2.5rem;
  font-weight: 100;
}

.about p {
  font-size: 1.3rem;
}

.diagonal {
  position: relative;
  isolation: isolate;
}

.diagonal::after {
  content: "";
  background-image: linear-gradient(
    45deg,
    var(--clr-primary),
    var(--clr-primary),
    var(--clr-primary)
  );
  position: absolute;
  z-index: -1;
  inset: 0;
  transform: skewY(-4deg);
}

.diag-wrapper {
  width: min(90%, 60rem);
  margin: 80px auto;
  padding-block: 100px;
  color: var(--clr-white);
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
}

.testimonial {
  font-size: 1.4rem;
  line-height: 2;
}

.author {
  text-align: center;
}

@media (max-width: 600px) {
  .about hr,
  .about h1 {
    margin-inline: auto;
    text-align: center;
  }
}
