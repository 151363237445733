.gallery-container {
  width: min(80%, 80rem);
  padding-block: 40px;
  margin-inline: auto;
}

.images {
  width: 100%;
  display: block;
  cursor: pointer;
  user-select: none;
}

.zoom-img-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-primary-95opacity);
  position: fixed;
}

.zoom-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  position: relative;
  margin-top: -80px;
}

.big-img {
  max-width: 90%;
  max-height: 80%;
}

.prev,
.next {
  color: var(--clr-white);
  font-size: 3rem;
  padding: 30px;
  cursor: pointer;
  position: absolute;
}

.prev {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.next {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 600px) {
  .prev,
  .next {
    font-size: 2rem;
  }

  .zoom-img {
    margin-top: -160px;
  }
}

@media (max-width: 350px) {
  .prev,
  .next {
    font-size: 1.5rem;
  }
}
