.gallery-item {
  position: relative;
  width: min(100%, 600px);
}

.gallery-thumbnail {
  height: 600px;
  width: 425px;
  border: 3px solid var(--clr-primary);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  filter: brightness(60%);
  transition: all 0.3s ease-in-out;
}

.gallery-item:hover .gallery-thumbnail,
.gallery-item h1 {
  filter: brightness(100%);
  color: var(--clr-primary);
}

.gallery-item h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  transform: translate(-50%, -50%);
  font-family: var(--font-accent);
  color: var(--clr-white);
}

@media (max-width: 1400px) {
  .gallery-thumbnail {
    width: 100%;
  }
}

@media (min-width: 600px) {
  .gallery-item:hover .gallery-thumbnail {
    transform: scale(1.1);
  }
}

@media (max-width: 350px) {
  .gallery-item h1 {
    font-size: 2rem;
  }
}
