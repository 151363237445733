.gallery-home {
  width: min(90%, 60rem);
  height: 100vh;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

@media (max-width: 1400px) {
  .gallery-home {
    height: auto;
    flex-direction: column;
    margin: 160px auto 80px;
  }
}
