.main-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/background.HEIC");
  background-size: cover;
  background-position: center;
  height: 110svh;
  height: 110dvh;
  height: 110vh;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -100;
}

.br {
  display: flex;
  color: var(--clr-white);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 102vh;
  font-size: 6.5rem;
  font-weight: 600;
  opacity: 100%;
  z-index: 100;
  animation: var(--fade-in);
}

@media (max-width: 1600px) {
  .br {
    flex-direction: column;
    font-size: 5.5rem;
  }
}

@media (max-width: 800px) {
  .br {
    font-size: 4rem;
  }

  .main-image {
    height: 100svh;
    height: 100dvh;
    height: 100vh;
  }
}

@media (max-width: 500px) {
  .br {
    font-size: 3rem;
    height: 90vh;
  }

  .main-image {
    height: 100svh;
    height: 100dvh;
    height: 100vh;
  }
}

@media (max-width: 400px) {
  .br {
    font-size: 2.5rem;
  }
}

@media (max-width: 375px) {
  .br {
    font-size: 1.5rem;
  }
}

.br-title {
  margin: 1rem;
  letter-spacing: 10px;
  font-family: var(--font-title);
}
