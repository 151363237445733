.gallery-cta,
.cta-container,
.img-container,
.cta-img,
.img-btn-wrapper {
  height: 600px;
  width: 100%;
}

.gallery-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.cta-container {
  background-color: var(--clr-primary);
  display: flex;
}

.title-wrapper {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-title {
  color: var(--clr-white);
  writing-mode: vertical-lr;
  font-size: 3.5rem;
  text-orientation: upright;
  line-height: 0.5;
  font-weight: bold;
}

.img-container {
  position: relative;
}

.cta-img {
  object-fit: cover;
}

.img-btn-wrapper {
  background-color: rgb(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1375px) {
  .img-container .img-btn-wrapper Button {
    padding: 25px 60px;
    font-size: 30px;
  }

  .cta-title {
    font-size: 2.5rem;
    letter-spacing: 6px;
  }

  .title-wrapper {
    width: 100px;
  }
}

@media (max-width: 865px) {
  .img-container .img-btn-wrapper Button {
    padding: 20px 50px;
    font-size: 20px;
  }

  .cta-title {
    font-size: 2rem;
    letter-spacing: 4px;
  }

  .title-wrapper {
    width: 80px;
  }
}

@media (max-width: 350px) {
  .img-container .img-btn-wrapper Button {
    padding: 15px 40px;
    font-size: 20px;
  }

  .cta-title {
    font-size: 1.5rem;
    letter-spacing: 4px;
  }

  .title-wrapper {
    width: 60px;
  }
}
