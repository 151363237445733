.navbar {
  top: 0;
  z-index: 999;
  position: fixed;
  width: 100%;
}

.spacer {
  width: 100%;
  height: 80px;
}

.container {
  display: flex;
  height: 80px;
  justify-content: space-between;
  background-color: var(--clr-primary-95opacity);
  align-items: center;
}

.navbar-logo {
  margin-left: 4rem;
  height: 70px;
  cursor: pointer;
  margin-top: 10px;
}

.menu-icon {
  color: var(--clr-white);
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 4rem;
}

.list-items {
  display: flex;
  padding: 10px;
  margin-right: 4rem;
  font-family: var(--font-title);
}

.hidden {
  display: none;
}

.nav-links {
  margin-right: 20px;
  font-size: 1.2rem;
  color: var(--clr-white);
}

.nav-links li {
  font-family: "Aboreto", cursive;
}

.nav-links:hover {
  color: var(--clr-gray);
}

.nav-bars {
  cursor: default;
  color: var(--clr-white);
  margin-right: 20px;
  font-size: 1.2rem;
  list-style: none;
}

li.dropdown {
  display: inline-block;
  font-family: var(--font-accent);
}

.dropdown-links {
  display: none;
  position: absolute;
  background-color: var(--clr-white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
}

.dropdown-links a {
  color: var(--clr-primary);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-family: var(--font-accent);
  font-size: 1.1rem; /* add this line */
}

.dropdown-links a:hover {
  background-color: var(--clr-primary);
  color: var(--clr-white);
}

.dropdown:hover .dropdown-links {
  display: block;
}

.mobile-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  background-color: var(--clr-primary);
  width: 85%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  opacity: 98%;
  z-index: 2000;
  animation: var(--slide-left);
}

.mobile-menu-icon {
  color: var(--clr-white);
  font-size: large;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 2rem;
}

.mobile-items {
  text-decoration: none;
  color: var(--clr-white);
  width: 100%;
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  transition: background-color 0.3s ease-in;
}

.mobile-items li {
  font-family: var(--font-accent);
}

.mobile-items:hover {
  background-color: var(--clr-white);
  color: var(--clr-primary);
  transform: scale();
}

@media (max-width: 600px) {
  .mobile-menu {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .mobile-menu-icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 900px) {
  .list-items {
    display: none;
  }
}

@media (max-width: 700px) {
  .menu-icon {
    margin-right: 2rem;
  }
}

@media (min-width: 900px) {
  .menu-icon {
    display: none;
  }
}

@media (max-width: 700px) {
  .navbar-logo {
    margin-left: 2rem;
  }
}

@media (max-width: 500px) {
  .navbar-logo {
    margin-left: 1rem;
  }
}
