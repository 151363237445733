.contact {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/background2.HEIC");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-box {
  background-color: var(--clr-primary-95opacity);
  width: min(90%, 975px);
  min-height: 600px;
  animation: var(--fade-in-short);
  line-height: 1.6;
  margin-top: 70px;
  padding: 60px;
  position: relative;
  display: flex;
  gap: 20px;
  box-shadow: 10px 16px 16px 0px rgba(0, 0, 0, 0.6);
  filter: brightness(100%);
}

.full-logo {
  width: 200px;
  height: 110px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.general-info {
  flex: 0.8;
  color: var(--clr-white);
  line-height: 1.7;
  position: relative;
}

.general-info h1 {
  font-size: 1.5rem;
}

.info hr {
  width: 15%;
  margin-block: 5px;
}

.contact-form {
  flex: 1;
}

.contact-form h1 {
  color: var(--clr-white);
  text-align: center;
  padding-bottom: 30px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.first-name-input,
.email-input,
.subject-input {
  width: 100%;
  height: 45px;
  min-height: 35px;
}

.first-name-input,
.email-input,
.subject-input,
.message-input {
  font-size: 1.3rem;
  padding-left: 10px;
}

.message-input {
  width: 100%;
  height: 150px;
  min-height: 100px;
  padding-top: 10px;
}

.contact-form Button {
  font-size: 1.5rem;
}

@media (max-width: 800px) {
  .contact-box {
    padding: 40px;
  }
}

@media (max-width: 1050px) {
  .contact {
    height: auto;
  }

  .contact-box {
    flex-flow: column-reverse;
    gap: 80px;
    margin: 120px 0 40px;
  }

  .contact-form h1 {
    font-size: 1.5rem;
  }

  .general-info {
    display: flex;
    justify-content: space-between;
  }

  .full-logo {
    position: unset;
  }

  .general-info p {
    font-size: 12px;
  }
  .general-info h1 {
    font-size: 1rem;
  }
}

@media (max-width: 550px) {
  .contact-box {
    gap: 40px;
  }

  .first-name-input,
  .email-input,
  .subject-input {
    width: 100%;
    height: 40px;
    min-height: 40px;
  }

  .first-name-input,
  .email-input,
  .subject-input,
  .message-input {
    font-size: 1rem;
  }

  .message-input {
    width: 100%;
    height: 100px;
    min-height: 100px;
    padding-top: 10px;
  }

  .full-logo {
    display: none;
  }

  .general-info {
    flex-direction: column;
    text-align: center;
    gap: 20px;
    align-items: center;
  }

  .info hr {
    margin-inline: auto;
  }
}
