.card-container {
  position: relative;
  background-color: var(--clr-primary);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  width: min(90%, 32rem);
  top: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--clr-white);
  height: auto;
  padding: 50px;
  box-shadow: 10px 5px 5px var(--clr-primary);
}

.card-text {
  padding-bottom: 40px;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.5;
  font-family: var(--font-accent);
}

@media (max-width: 800px) {
  .card-container {
    padding: 40px;
  }

  .card-text {
    font-size: 1.4rem;
  }
}

@media (max-width: 800px) {
  .card-container Button {
    bottom: 4rem;
  }
}
