.swiper {
  height: 500px;
  width: 100%;
  background-color: var(--clr-accent);
  margin: 40px 0;
}

@media (max-width: 900px) {
  .swiper {
    width: 100%;
  }
}

.swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  cursor: grab;
  background-color: var(--clr-accent);
}

.slide-container {
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--clr-primary) !important;
  padding: 40px 40px;
  border-radius: 50%;
}

.swiper-slide h1,
.swiper-slide p,
.quote-icon {
  margin: 10px;
  text-align: center;
}

.swiper-slide h1 {
  font-size: 3rem;
  max-width: 50rem;
  color: var(--clr-primary);
}

.swiper-slide p {
  font-size: 1rem;
  color: var(--clr-gray);
}

.quote-icon {
  font-size: 1.5rem;
}

.swiper-pagination-bullet-active {
  background-color: var(--clr-primary) !important;
}

@media (max-width: 800px) {
  .swiper-button-next,
  .swiper-button-prev {
    padding: 0;
  }
}

@media (max-width: 950px) {
  .swiper-slide h1 {
    font-size: 2rem;
    max-width: 400px;
  }
}

@media (max-width: 500px) {
  .swiper-slide h1 {
    font-size: 1.3rem;
  }
}

@media (max-width: 400px) {
  .swiper-slide h1 {
    max-width: 250px;
  }
}
