.dashboard-img {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../../assets/background.HEIC");
  background-size: cover;
  background-position: center;
  height: 100svh;
  height: 100dvh;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.upload-box {
  background-color: var(--clr-primary);
  height: 25rem;
  width: min(90%, 25rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.upload-box img {
  height: 80px;
}

.upload-box h1 {
  color: var(--clr-white);
}

.logout {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  color: var(--clr-white);
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid var(--clr-white);
  padding: 8px 16px;
  transition: all 0.3s;
}

.logout:hover {
  background-color: var(--clr-white);
  color: var(--clr-primary);
}

@media (min-width: 600px) {
  .logout {
    bottom: 5rem;
    right: 8rem;
  }
}
